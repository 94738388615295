.header {
  z-index: 3;
  position: sticky;
  top: 0;
  background-color: white;
  box-shadow: 0 0 13px 0 rgb(175 206 252 / 35%);
}

.wrapper {
  display: flex;
  justify-content: space-between;
  width: 1200px;
  margin: 0 auto;
  align-items: center;
  height: 64px;
}

.globalImg {
  height: 20px;
}

.lanWrapper {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
}

.right {
  display: inline-flex;
  align-items: center;
  color: #110929;
}

.btn {
  margin-left: 30px;
  border-radius: 3px !important;

  &:last-child {
    margin-left: 16px;
  }
}
