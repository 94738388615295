.panel {
  padding: 12px 0;
  margin-bottom: 4px;
  background-color: var(--semi-color-white);
  border-bottom: 1px solid var(--semi-color-border);
  cursor: pointer;
  display: flex;
  align-items: flex-start;
}

.contentContainer {
  flex: 1;
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  composes: cnRegular from "@scss/global.scss";
  color: var(--semi-color-text-1);
  font-weight: 600;
}

.iconContainer {
  padding-right: 8px;
  font-size: 16px;
}

.desc {
  composes: cnRegular from "@scss/global.scss";
  color: var(--semi-color-text-1);
  margin-top: 4px;
}

.info {
  color: var(--semi-color-primary);
}

.danger {
  color: var(--semi-color-danger);
}

.success {
  color: var(--semi-color-success);
}

.warning {
  color: var(--semi-color-warning);
}

.time {
  composes: cnSmallRegular from "@scss/global.scss";
  color: var(--semi-color-text-2);
  display: inline-flex;
  align-items: center;
}

.deleteBtn {
  font-size: 14px;
}

.deleteBtn:hover {
  color: var(--semi-color-primary);
}

.footer {
  composes: cnSmallRegular from "@scss/global.scss";
  color: var(--semi-color-text-2);
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
}

.read .iconContainer {
  opacity: 0.5;
}

.read .titleContainer {
  font-weight: 400;
}

.read .title {
  color: var(--semi-color-text-2);
}

.read .desc,
.read .time,
.read .footer {
  color: var(--semi-color-text-3);
}

.downloadContainer {
  margin-top: 12px;

  .btnDisable {
    color: #bbbfc4 !important;
    pointer-events: none;
  }
}
