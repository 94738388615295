.enMedium {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
}

.cnSmallRegular {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.cnHeader1Regular {
  font-style: normal;
  font-weight: 400;
  font-size: 29px;
  line-height: 38px;
}

.cnHeader3Regular {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
}

.cnRegular {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.cnHeader4Bold {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 26px;
}

.enHeader3Bold {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
}

.page-title {
  @extend .cnHeader1Regular;

  color: var(--semi-color-text-0);
  margin-bottom: 12px;
  font-size: 24px;
  font-weight: bold;
}

.search-form {
  display: flex;

  .content-section {
    flex: 1;
    display: flex;
    flex-wrap: wrap;

    .semi-form-field {
      margin-bottom: 12px;
    }
  }

  .btn-section {
    flex: 0 0 200px;
    text-align: right;

    .reset-btn {
      margin-right: 12px;
    }
  }
}

.operation-container {
  margin: 24px 0;

  button {
    margin-right: 12px;
  }
}

.parameter-section {
  margin: 4px 0 16px;

  .tag {
    color: var(--semi-color-primary);
    background-color: var(--semi-color-primary-light-hover);
    margin-right: 10px;
    margin-bottom: 8px;
  }
}

.operation-column {
  white-space: nowrap;
  color: var(--semi-color-text-2);

  .btn {
    margin-right: 10px;
    color: var(--semi-color-text-2);
    cursor: pointer;

    &:hover {
      color: var(--semi-color-primary);
    }
  }
}

.icon-btn {
  color: var(--semi-color-text-2);
  cursor: pointer;

  &:hover {
    color: var(--semi-color-primary);
  }
}

.hightlight-btn {
  background-color: var(--semi-color-primary-light-default) !important;
  font-weight: 600 !important;

  &:hover {
    background-color: var(--semi-color-primary-light-hover) !important;
  }

  &:active {
    background-color: var(--semi-color-primary-light-active) !important;
  }
}

.default-btn {
  &:hover {
    background-color: var(--semi-color-primary-light-hover) !important;
  }

  &:active {
    background-color: var(--semi-color-primary-light-active) !important;
  }
}

.no-background-btn {
  padding-left: 0 !important;
  padding-right: 0 !important;

  .semi-button-content-right {
    margin-left: 6px;
  }

  &:hover {
    background-color: transparent !important;
  }

  &:active {
    background-color: transparent !important;
  }
}

.danger-btn {
  background-color: var(--semi-color-danger-light-default) !important;
}

.warning-btn {
  background-color: var(--semi-color-warning-light-default) !important;
}

.job-container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .header-section {
    display: flex;
    justify-content: space-between;
    flex: 0 0 34px;

    .job-name {
      @extend .cnHeader4Bold;

      display: inline-flex;
      align-items: center;
    }

    .tooltip-wrapper {
      display: inline-flex;
      align-items: center;
      margin-left: 4px;
    }

    .btn-container {
      font-size: 24px;
      color: var(--semi-color-tertiary);
      display: inline-flex;
      align-items: center;

      .btn {
        cursor: pointer;
      }
    }
  }

  .form {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: calc(100% - 34px);
    min-height: calc(100% - 34px);

    .top-section {
      .add-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 4px 0;

        .left {
          display: flex;
          align-items: center;
        }
      }

      .btn {
        font-size: 16px;
        color: var(--semi-color-tertiary);
        cursor: pointer;

        &:hover {
          color: var(--semi-color-primary);
        }
      }
    }

    .label-bold {
      label {
        font-weight: 600;
      }
    }

    .label-small {
      label {
        font-size: 12px;
      }
    }

    .top-small-padding {
      padding-top: 4px;
    }

    .bottom-small-padding {
      padding-bottom: 4px;
    }

    .scroll-section {
      overflow-y: scroll;
      margin-right: -22px;
      padding-right: 16px;
      flex: 1;

      .edit-btn-wrapper {
        font-size: 12px;
        padding: 0 12px 0 8px;

        & > span {
          cursor: pointer;
        }

        .cancel-btn {
          color: var(--semi-color-text-2);
        }

        .ok-btn {
          color: var(--semi-color-primary);
          margin-left: 8px;
        }
      }

      .name-section {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 6px;
        height: 28px;

        .text {
          flex: 1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: var(--semi-color-text-0);
        }

        .btn-wrapper {
          color: var(--semi-color-text-2);
          font-size: 20px;
          flex: 0 0 46px;
          text-align: right;
          display: none;

          .btn {
            cursor: pointer;
            font-size: 14px;
            margin-left: 8px;
          }
        }

        &:hover .btn-wrapper {
          display: block;
        }
      }

      .input {
        padding: 0;

        .semi-form-field-label {
          margin-top: 8px;
          margin-bottom: 4px;
          color: var(--semi-color-text-1);
        }
      }

      .space-between {
        .semi-form-field-label {
          justify-content: space-between;
        }
      }

      .chain-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        color: var(--semi-color-text-2);
        margin-bottom: 4px;
        margin-top: 8px;

        .left {
          display: flex;
          justify-content: center;
        }

        .btn {
          cursor: pointer;
        }

        .semi-form-field {
          padding: 0;
        }

        .semi-input-wrapper-small {
          height: 20px;
          line-height: 18px;
          font-size: 12px;
        }

        .semi-input-small {
          height: 18px;
        }
      }

      .add-btn {
        color: var(--semi-color-primary);
        font-size: 12px;
        cursor: pointer;
        margin-top: 8px;
        display: inline-block;
      }

      .big-gap {
        padding-top: 16px !important;
      }

      .small-gap {
        padding-top: 4px !important;
      }
    }

    .footer {
      position: relative;

      .collapsible-section {
        text-align: center;
        font-size: 12px;
        color: var(--semi-color-primary);
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .section-label {
        color: var(--semi-color-text-0);
        font-weight: 600;
        margin: 8px 0;
      }

      .divider-wrapper {
        position: relative;
        margin-left: -24px;
        margin-right: -24px;

        .divider {
          border-bottom: 1px solid var(--semi-color-primary-light-hover);
        }
      }

      .semi-form-field {
        padding-top: 4px;
        padding-bottom: 4px;
        align-items: flex-start;

        &.label-bold {
          padding-top: 8px;
        }
      }

      .params-container {
        .semi-form-field[x-label-pos="left"] {
          .semi-form-field-label {
            font-size: 12px;
            padding-right: 10px;
            padding-bottom: 0;
          }
        }

        .semi-select-selection,
        .semi-cascader-selection {
          font-size: 12px;
        }

        .semi-input-wrapper-small {
          font-size: 12px;
        }
      }

      .action-container {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-top: 12px;

        .statistics-text {
          @extend .cnSmallRegular;

          flex: 0 0 165px;
          color: var(--semi-color-text-2);
        }

        .btn-container {
          white-space: nowrap;
        }

        .btn {
          &:not(:first-child) {
            margin-left: 12px;
          }
        }
      }
    }

    .help {
      color: var(--semi-color-text-2);
    }

    .link {
      font-size: 12px;
      line-height: 16px;
      color: var(--semi-color-link);
      cursor: pointer;
    }

    .select {
      .semi-tag-group {
        width: 100%;
      }
    }
  }
}

.semi-table-container {
  border: 1px solid var(--semi-color-border-1);
  border-radius: 4px;
  overflow: hidden;
}

.semi-table-thead {
  & > .semi-table-row {
    .react-resizable-handle {
      width: 2px !important;
      height: 16px !important;
      bottom: 50% !important;
      background-color: var(--semi-color-border-2) !important;
      transform: translateY(50%);

      &::before {
        position: absolute;
        content: "";
        display: block;
        width: 4px;
        left: -4px;
        height: 100%;
      }
    }
  }
}

::-webkit-scrollbar {
  appearance: none;
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: var(--semi-color-border-2);
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--semi-color-border-3);
}

/* input */
:global(.semi-input-wrapper-clearable)
  :global(.semi-input-clearbtn):hover
  :global(.semi-icon-clear) {
  color: var(--semi-color-text-1);
}

/* date-picker */
:global(.semi-datepicker-range-input) {
  border: 1px solid var(--semi-color-border-2) !important;
  background-color: transparent;
}

:global(.semi-datepicker-range-input):hover {
  border: 1px solid var(--semi-color-border-3);
  background-color: transparent;
}

:global(.semi-datepicker) :global(.semi-icon-clear):hover {
  color: var(--semi-color-text-1) !important;
}

:global(.semi-datepicker):hover :global(.semi-icon-clear) {
  color: var(--semi-color-text-2);
}

/* dropdown select */
:global(.semi-tag-white-light) {
  background-color: var(--semi-color-primary-light-hover) !important;
  border: none !important;
  margin: 2px 2px 2px 0 !important;
}

:global(.semi-tag-close):hover {
  color: var(--semi-color-text-1);
}

:global(.semi-select-clear):hover {
  color: var(--semi-color-text-1);
}

:global(.semi-select-option-selected) :global(.semi-select-option-icon) {
  color: var(--semi-color-text-2) !important;
}

/* 变量区域 */

:root {
  --cardTopAndBottomPadding: 16px;
  --cardLeftAndRightPadding: 24px;
}

.semi-empty {
  .semi-typography {
    color: var(--semi-color-text-2);
  }
}

.semi-input-number-suffix-btns {
  border-radius: 0 3px 3px 0;
  border: none;
  overflow: hidden;
}

/* table head scroll box shadow */
.semi-table-thead {
  & > .semi-table-row {
    & > .semi-table-row-head {
      padding-top: 10px !important;
      padding-bottom: 10px !important;
      background-color: var(--semi-color-disabled-fill) !important;
      border-bottom: 1px solid var(--semi-color-border-1) !important;

      &.semi-table-cell-fixed-left-last {
        border-right: none !important;
        box-shadow: 3px 0 5px var(--semi-color-shadow) !important;
      }

      &.semi-table-cell-fixed-right-first {
        border-left: none !important;
        box-shadow: -3px 0 5px var(--semi-color-shadow) !important;
      }
    }
  }
}

/* table body scroll box shadow */
.semi-table-tbody {
  & > .semi-table-row {
    & > .semi-table-cell-fixed-left-last {
      border-right: none !important;
      box-shadow: 3px 0 5px var(--semi-color-shadow) !important;
    }

    & > .semi-table-cell-fixed-right-first {
      border-left: none !important;
      box-shadow: -3px 0 5px var(--semi-color-shadow) !important;
    }
  }
}

.semi-table-scroll-position-left {
  .semi-table-thead {
    & > .semi-table-row {
      & > .semi-table-row-head {
        &.semi-table-cell-fixed-left-last {
          box-shadow: none !important;
        }
      }
    }
  }

  .semi-table-tbody {
    & > .semi-table-row {
      & > .semi-table-cell-fixed-left-last {
        box-shadow: none !important;
      }
    }
  }
}

.semi-table-scroll-position-right {
  .semi-table-thead {
    & > .semi-table-row {
      & > .semi-table-row-head {
        &.semi-table-cell-fixed-right-first {
          box-shadow: none !important;
        }
      }
    }
  }

  .semi-table-tbody {
    & > .semi-table-row {
      & > .semi-table-cell-fixed-right-first {
        box-shadow: none !important;
      }
    }
  }
}

.full-height-page {
  height: 100%;
  display: flex;
  flex-direction: column;

  .scroll-section {
    flex-grow: 1;
    flex-shrink: 1;
    margin-bottom: 12px;
  }

  .table {
    flex-grow: 1;
    flex-shrink: 1;
    min-height: 0;
    margin-bottom: 12px;

    .semi-spin {
      height: 100%;
    }

    .semi-spin-children {
      height: 100%;

      .semi-table-virtualized {
        .semi-table-tbody {
          & > .semi-table-row {
            & > .semi-table-row-cell {
              display: unset;
            }
          }
        }
      }

      & > div {
        height: 100%;

        .semi-table-container {
          height: 100%;
          display: flex;
          flex-direction: column;

          .semi-table-header-sticky {
            flex: 0 0 auto;

            &::-webkit-scrollbar-track {
              background-color: var(--semi-color-disabled-fill) !important;
            }
          }

          .semi-table-body {
            flex-grow: 1;
            flex-shrink: 1;
            max-height: 100% !important;
            margin-bottom: -1px;
          }

          .semi-table-placeholder {
            height: calc(100% - 41px);
            padding: 0 !important;
            border-bottom: none !important;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }

  .table-pagination {
    justify-content: flex-end;
  }
}

.semi-button-light {
  &.semi-button-tertiary {
    background-color: transparent !important;
    border: 1px solid var(--semi-color-border-2) !important;

    &:hover {
      color: var(--semi-color-primary) !important;
      border: 1px solid var(--semi-color-primary) !important;
      background-color: transparent !important;
    }

    &:active {
      color: #0062d6 !important;
      border: 1px solid #0062d6 !important;
    }
  }

  &.semi-button-disabled {
    border: 1px solid #dee0e3 !important;
    color: #bbbfc4 !important;
    opacity: 0.8;
    background-color: transparent !important;
  }
}

.link-btn {
  cursor: pointer;
  color: var(--semi-color-primary) !important;

  &:hover {
    text-decoration: underline;
  }
}

.link-wrapper {
  white-space: nowrap;

  .link-btn:not(:first-child) {
    margin-left: 12px;
  }
}

:focus {
  outline: none !important;
}

.input-group.semi-input-group {
  .semi-select:not(:last-child) {
    border-right: none;

    &::after {
      top: -1px;
      bottom: -1px;
      z-index: 1;
      background-color: var(--semi-color-border-2);
    }

    &.semi-select-error {
      &::after {
        background-color: var(--semi-color-danger);
      }
    }

    &.semi-select-focus {
      &::after {
        z-index: 2;
        background-color: var(--semi-color-focus-border);
      }
    }
  }

  .semi-input-wrapper {
    border-left: none;

    &::after {
      content: "";
      background-color: var(--semi-color-border-2);
      width: 1px;
      position: absolute;
      left: 0;
      top: -1px;
      bottom: -1px;
    }

    &.semi-input-wrapper-focus {
      &::after {
        background-color: var(--semi-color-focus-border);
        z-index: 2;
      }
    }
  }
}

.semi-toast-wrapper {
  .semi-toast-close-button {
    .semi-button-size-small {
      height: 24px;
    }
  }
}

.semi-cascader {
  .semi-cascader-clearbtn {
    &:hover {
      color: var(--semi-color-text-1);
    }
  }
}

.job-page {
  height: 100%;
  background-color: white;
  border-radius: 6px 6px 0 0;
  padding: 24px 24px 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  .semi-form-field-label-text {
    color: var(--semi-color-text-1);
  }

  .content {
    flex: 1;
    box-sizing: border-box;
    overflow: hidden auto;
    margin-right: -22px;
    padding-right: 16px;
    padding-left: 10px;

    .section-label {
      font-size: 17px;
      font-weight: 600;
      line-height: 26px;
      position: relative;
      margin-bottom: 10px;
      display: flex;
      align-items: center;

      &:not(:first-child) {
        margin-top: 10px;
      }

      &::before {
        content: "";
        position: absolute;
        left: -10px;
        top: 5px;
        width: 3px;
        height: 16px;
        background-color: var(--semi-color-primary);
      }

      .upload-btn {
        font-size: 20px;
        color: var(--semi-color-tertiary);
        cursor: pointer;
        margin-left: 8px;
      }
    }

    .statistics-line {
      font-size: 12px;
      color: var(--semi-color-text-2);
      margin-top: 4px;
      padding-left: 12px;
    }

    .introduce {
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 6px;
      margin-top: 6px;
    }

    .extra-text {
      font-size: 12px;
    }
  }

  .footer {
    border-top: 1px solid #f2f3f5;
    padding-top: 16px;
    text-align: center;
    margin-left: -24px;
    margin-right: -24px;

    .btn:not(:first-child) {
      margin-left: 12px;
    }
  }
}

.sequence-input-container {
  border: 1px solid var(--semi-color-border-1);
  border-radius: 4px;
  overflow: hidden;

  .sequence-header {
    display: flex;
    height: 40px;
    background-color: var(--semi-color-disabled-fill);
    font-weight: 600;
    box-sizing: border-box;
    border-bottom: 1px solid var(--semi-color-border-1);

    .num-column {
      padding: 8px 0;
      text-align: center;
    }

    .name-column {
      padding: 8px 12px;
    }

    .sequence-column {
      padding: 8px 12px;
    }

    .operate-column {
      padding: 8px 12px;
    }
  }

  .sequence-body {
    .title-input-row {
      display: flex;
      background-color: var(--semi-color-primary-light-default);

      &:not(:first-child) {
        border-top: 1px solid var(--semi-color-border-1);
      }

      .name-container {
        display: flex;
        margin-top: 4px;
        width: 96%;

        &:hover {
          .pen {
            display: block;
          }
        }

        .pen {
          font-size: 16px;
          color: var(--semi-color-text-2);
          cursor: pointer;
          margin-left: 8px;
          display: none;
        }
      }

      .return-icon {
        color: var(--semi-color-text-2);
        margin-right: 8px;
        cursor: pointer;
      }

      .num-column {
        padding: 10px 0;
        text-align: center;
      }

      .name-column {
        padding: 6px 12px;
      }

      .sequence-column {
        padding: 6px 12px;
        width: 100%;
      }

      .operate-column {
        display: flex;
        padding: 10px 12px;
      }
    }

    .sequence-content {
      padding: 8px 0;
    }

    .content-input-row {
      display: flex;

      .semi-form-field {
        padding-top: 4px;
        padding-bottom: 4px;
      }

      .name-container {
        display: flex;

        &:hover {
          .pen {
            display: block;
          }
        }

        .pen {
          font-size: 16px;
          color: var(--semi-color-text-2);
          cursor: pointer;
          margin-left: 8px;
          display: none;
        }
      }

      .return-icon {
        color: var(--semi-color-text-2);
        margin-right: 8px;
        cursor: pointer;
      }

      .num-column {
        padding: 10px 0;
        text-align: center;
      }

      .name-column {
        padding: 10px 12px 12px;
        color: var(--semi-color-text-2);
      }

      .sequence-column {
        padding: 0 12px;
      }

      .operate-column {
        padding: 10px 12px;
      }

      .btn {
        color: var(--semi-color-text-2);
        font-size: 16px;
        cursor: pointer;

        &:not(:first-child) {
          margin-left: 4px;
        }
      }
    }
  }

  .sequence-footer {
    display: flex;
    height: 40px;
    font-size: 24px;
    color: var(--semi-color-primary);
    cursor: pointer;
    box-sizing: border-box;
    border-top: 1px solid var(--semi-color-border-1);

    .left {
      padding: 6px 8px;
      flex: 1;
    }
  }

  .num-column {
    flex: 0 0 40px;
    box-sizing: border-box;
  }

  .name-column {
    flex: 0 0 210px;
    box-sizing: border-box;
    width: 210px;
  }

  .sequence-column {
    flex: 1;
    box-sizing: border-box;
  }

  .operate-column {
    flex: 0 0 80px;
    box-sizing: border-box;
  }
}
