@font-face {
  font-family: Poppins;
  src: url("./assets/fonts/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Mono";
  src: url("./assets/fonts/RobotoMono-VariableFont_wght.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: "PingFang SC", -apple-system, BlinkMacSystemFont, Arial,
    "Segoe UI", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue",
    Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(244 250 255);
  min-width: 1366px;
  overflow-y: hidden;
}

body.no-limit {
  min-width: auto;
}
