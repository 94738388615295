.footer {
  background: #0f1621;
  color: #fff;
}

.content {
  width: 1400px;
  margin: 0 auto;
  border: 1px solid transparent;
  padding-top: 53px;
  padding-bottom: 40px;

  .switchContainer {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-top: 38px;

    .img {
      filter: brightness(0) invert(1);
      width: 18px;
    }

    .text {
      margin-left: 10px;
    }
  }

  .textTitle {
    font-size: 16px;
    font-family: HelveticaNeue, HelveticaNeue;
    margin-top: 27px;
    font-weight: bold;
  }

  .subText {
    font-size: 12px;
    font-family: HelveticaNeue;
    margin-top: 22px;
  }
}

.row {
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
  box-sizing: border-box;
  border-bottom: 1px solid #333;

  .col {
    &:global(:first-child) {
      width: 290px;
    }

    &:global(:nth-child(2)) {
      width: 484px;
    }

    &:global(:nth-child(3)) {
      width: 336px;
    }

    &:global(:nth-child(4)) {
      width: 290px;
    }
  }
}

.copyRightWrapper {
  font-size: 12px;
  font-family: "HiraginoSansGB-W3", HiraginoSansGB;
  color: #999;
}

.link {
  color: #999;
  text-decoration: none;

  &:hover {
    color: var(--semi-color-primary);
  }
}

.whiteLink {
  color: white;
  text-decoration: none;

  &:hover {
    color: var(--semi-color-primary);
  }
}

.imageContainer {
  display: flex;
  align-items: center;
  margin: 30px 0;

  .tradeMark {
    width: 172px;
  }

  .iconContainer {
    margin-left: 50px;
  }

  .imgContainer {
    margin-left: 12px;
  }

  .icon {
    width: 36px;
  }
}

.popoverWrapper {
  position: relative;
  display: inline-block;

  .popoverContent {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    transform: translate(-32%, 10px);
    width: 100px;
    background-color: transparent;
    bottom: 60px;
    left: 0;

    img {
      width: 100px;
      border-radius: 8px;
    }

    &::before {
      position: absolute;
      z-index: 0;
      content: "";
      right: calc(50% - 10px);
      bottom: -13px;
      border-style: solid;
      border-width: 10px;
      border-color: white transparent transparent;
      transition-duration: 0.3s;
      transition-property: transform;
    }
  }

  &:hover {
    .popoverContent {
      z-index: 10;
      opacity: 1;
      visibility: visible;
      transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
    }
  }
}
