.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 24px 12px;
  composes: cnHeader4Bold from "@scss/global.scss";
  color: var(--semi-color-text-1);
}

.listContainer {
  height: 420px;
  overflow: auto;
  width: 380px;
}

.listContainer :global(.semi-list-item-body) {
  display: block;
}

.listContainer :global(.semi-list-item) {
  padding: 0 24px;
  border-bottom: none !important;
}
